import React from 'react';
import styled from 'styled-components';

import { useCurrentTheme } from 'app/shared/theme';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';
import { ReactComponent as CheckCircle } from 'icons/sofar-icons/checkCircle.svg';
import { ReactComponent as ArrowLeft } from 'icons/streamline-regular/arrows-diagrams/arrows/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'icons/streamline-regular/arrows-diagrams/arrows/arrow-right.svg';
import { ReactComponent as Archive } from 'icons/streamline-regular/content/archives/archive.svg';
import { ReactComponent as NotesBookText } from 'icons/streamline-regular/content/notes/notes-book-text.svg';
import { ReactComponent as AlertTriangle } from 'icons/streamline-regular/interface-essential/alerts/alert-triangle.svg';
import { ReactComponent as Bin1 } from 'icons/streamline-regular/interface-essential/delete/bin-1.svg';
import { ReactComponent as Pencil } from 'icons/streamline-regular/interface-essential/edit/pencil.svg';
import { ReactComponent as Checklist } from 'icons/streamline-regular/interface-essential/form-edition/checklist.svg';
import { ReactComponent as Check1 } from 'icons/streamline-regular/interface-essential/form-validation/check-1.svg';
import { ReactComponent as Close } from 'icons/streamline-regular/interface-essential/form-validation/close.svg';
import { ReactComponent as ListBullets } from 'icons/streamline-regular/interface-essential/lists/list-bullets.svg';
import { ReactComponent as Add } from 'icons/streamline-regular/interface-essential/remove-add/add.svg';
import { ReactComponent as AddCircle } from 'icons/streamline-regular/interface-essential/remove-add/add-circle.svg';
import { ReactComponent as SubtractCircle } from 'icons/streamline-regular/interface-essential/remove-add/subtract-circle.svg';
import { ReactComponent as SearchPlain } from 'icons/streamline-regular/interface-essential/search/search.svg';
import { ReactComponent as SearchCircle } from 'icons/streamline-regular/interface-essential/search/search-circle.svg';
import { ReactComponent as DownloadBottom } from 'icons/streamline-regular/internet-networks-servers/upload-download/download-bottom.svg';
import { ReactComponent as Bandcamp1 } from 'icons/streamline-regular/logos/music/bandcamp-1.svg';
import { ReactComponent as SocialMusicSoundcloud } from 'icons/streamline-regular/logos/music/social-music-soundcloud.svg';
import { ReactComponent as SocialMusicSpotify2 } from 'icons/streamline-regular/logos/music/social-music-spotify-2.svg';
import { ReactComponent as SocialInstagram1 } from 'icons/streamline-regular/logos/photos-images/social-instagram-1.svg';
import { ReactComponent as SocialMediaFacebook } from 'icons/streamline-regular/logos/social-medias/social-media-facebook.svg';
import { ReactComponent as SocialMediaTwitter } from 'icons/streamline-regular/logos/social-medias/social-media-twitter.svg';
import { ReactComponent as SocialVideoYoutubeClip } from 'icons/streamline-regular/logos/videos/social-video-youtube-clip.svg';
import { ReactComponent as MusicNote2 } from 'icons/streamline-regular/music-audio/music/music-note-2.svg';
import { ReactComponent as BrowserPageAccount } from 'icons/streamline-regular/programing-apps-websites/website-development/browser-page-account.svg';
import { ReactComponent as Baggage } from 'icons/streamline-regular/travel/baggage/baggage.svg';

const colors = (theme: any) => ({
  black: theme.colors.backToBlack,
  white: theme.colors.whiteDenim,
  grey: theme.colors.blueSmoke,
  green: theme.colors.green600,
  red: theme.colors.redRedWine,
});

const actionIconStroke = (theme: any, disabled?: boolean) =>
  disabled ? colors(theme).grey : colors(theme).green;

interface ActionIconWrapperProps {
  disabled?: boolean;
}

const ActionIconWrapper = styled.div<ActionIconWrapperProps>`
  ${props => (props.disabled ? '' : 'cursor: pointer;')}
`;

const IconWrapper = styled.span``;

interface BaseIconProps {
  icon: any;
  iconSize?: number;
  iconStrokeColor?: string;
  iconHoverStrokeColor?: string;
  iconFillColor?: string;
  onClick?: () => void;
  disabled?: boolean;
  dataQaid: string;
  className?: string;
}

const BaseIcon: React.FC<BaseIconProps> = ({
  icon,
  iconSize = 16,
  iconStrokeColor = 'green',
  iconHoverStrokeColor = 'black',
  iconFillColor = 'none',
  onClick,
  disabled,
  dataQaid,
  className,
}) => {
  const theme = useCurrentTheme();

  return (
    <div className={className}>
      {onClick ? (
        <ActionIconWrapper
          onClick={onClick}
          disabled={disabled}
          data-qaid={dataQaid}
        >
          <StreamlineIcon
            icon={icon}
            size={iconSize}
            stroke={actionIconStroke(theme, disabled)}
          />
        </ActionIconWrapper>
      ) : (
        <IconWrapper data-qaid={dataQaid}>
          <StreamlineIcon
            icon={icon}
            size={iconSize}
            stroke={colors(theme)[iconStrokeColor] || iconStrokeColor}
            hoverStroke={
              colors(theme)[iconHoverStrokeColor] || iconHoverStrokeColor
            }
            fill={colors(theme)[iconFillColor] || iconFillColor}
          />
        </IconWrapper>
      )}
    </div>
  );
};

interface IconProps {
  iconSize?: number;
  iconColor?: string;
  iconHoverColor?: string;
  onClick?: () => void;
  disabled?: boolean;
  dataQaid?: string;
  className?: string;
}

export const ViewIcon: React.FC<IconProps> = ({
  iconSize = 16,
  iconColor,
  onClick,
  disabled = false,
  dataQaid = 'view-icon',
}) => {
  return (
    <BaseIcon
      icon={NotesBookText}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const AddIcon: React.FC<IconProps> = ({
  iconSize = 16,
  iconColor,
  onClick,
  disabled = false,
  dataQaid = 'add-icon',
}) => {
  return (
    <BaseIcon
      icon={Add}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const EditIcon: React.FC<IconProps> = ({
  iconSize = 16,
  onClick,
  disabled = false,
  dataQaid = 'edit-icon',
}) => {
  return (
    <BaseIcon
      icon={Pencil}
      iconSize={iconSize}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const DeleteIcon: React.FC<IconProps> = ({
  iconSize = 16,
  onClick,
  disabled = false,
  dataQaid = 'delete-icon',
}) => {
  return (
    <BaseIcon
      icon={Bin1}
      iconSize={iconSize}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const ArchiveIcon: React.FC<IconProps> = ({
  iconSize = 16,
  onClick,
  disabled = false,
  dataQaid = 'archive-icon',
}) => {
  return (
    <BaseIcon
      icon={Archive}
      iconSize={iconSize}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const ListIcon: React.FC<IconProps> = ({
  iconSize = 16,
  onClick,
  disabled = false,
  dataQaid = 'list-icon',
}) => {
  return (
    <BaseIcon
      icon={ListBullets}
      iconSize={iconSize}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const ChecklistIcon: React.FC<IconProps> = ({
  iconSize = 16,
  iconColor = 'green',
  onClick,
  disabled = false,
  dataQaid = 'checklist-icon',
}) => {
  return (
    <BaseIcon
      icon={Checklist}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const ArrowLeftIcon: React.FC<IconProps> = ({
  iconSize = 14,
  onClick,
  disabled = false,
  dataQaid = 'arrow-left-icon',
}) => {
  return (
    <BaseIcon
      icon={ArrowLeft}
      iconSize={iconSize}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const ArrowRightIcon: React.FC<IconProps> = ({
  iconSize = 14,
  onClick,
  disabled = false,
  dataQaid = 'arrow-right-icon',
}) => {
  return (
    <BaseIcon
      icon={ArrowRight}
      iconSize={iconSize}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const SearchIcon: React.FC<IconProps> = ({
  onClick,
  dataQaid = 'search-icon',
}) => {
  return (
    <BaseIcon
      icon={SearchCircle}
      iconSize={20}
      onClick={onClick}
      dataQaid={dataQaid}
    />
  );
};

export const SearchPlainIcon: React.FC<IconProps> = ({
  iconSize = 20,
  iconColor = 'black',
  onClick,
  dataQaid = 'search-plain-icon',
}) => {
  return (
    <BaseIcon
      icon={SearchPlain}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      dataQaid={dataQaid}
    />
  );
};

export const DownloadIcon: React.FC<IconProps> = ({
  iconSize = 16,
  iconColor,
  onClick,
  disabled = false,
  dataQaid = 'download-icon',
}) => {
  return (
    <BaseIcon
      icon={DownloadBottom}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const CloseIcon: React.FC<IconProps> = ({
  iconSize = 15,
  iconColor = 'grey',
  dataQaid = 'close-icon',
}) => {
  return (
    <BaseIcon
      icon={Close}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      dataQaid={dataQaid}
    />
  );
};

export const YesIcon: React.FC<IconProps> = ({ dataQaid = 'yes-icon' }) => {
  return (
    <BaseIcon
      icon={Check1}
      iconSize={12}
      iconStrokeColor="green"
      dataQaid={dataQaid}
    />
  );
};

export const NoIcon: React.FC<IconProps> = ({ dataQaid = 'no-icon' }) => {
  return (
    <BaseIcon
      icon={Close}
      iconSize={12}
      iconStrokeColor="grey"
      dataQaid={dataQaid}
    />
  );
};

export const ToggleOpenIcon: React.FC<IconProps> = ({
  iconHoverColor = undefined,
  dataQaid = 'toggle-open-icon',
}) => {
  return (
    <BaseIcon
      icon={AddCircle}
      iconSize={14}
      iconStrokeColor="green"
      iconHoverStrokeColor={iconHoverColor}
      dataQaid={dataQaid}
    />
  );
};

export const ToggleCloseIcon: React.FC<IconProps> = ({
  iconHoverColor = undefined,
  dataQaid = 'toggle-close-icon',
}) => {
  return (
    <BaseIcon
      icon={SubtractCircle}
      iconSize={14}
      iconStrokeColor="green"
      iconHoverStrokeColor={iconHoverColor}
      dataQaid={dataQaid}
    />
  );
};

export const AlertIcon: React.FC<IconProps> = ({
  iconSize = 38,
  dataQaid = 'alert-icon',
}) => {
  return (
    <BaseIcon
      icon={AlertTriangle}
      iconSize={iconSize}
      iconStrokeColor="red"
      dataQaid={dataQaid}
    />
  );
};

export const CheckIcon: React.FC<IconProps> = ({
  iconSize = 16,
  iconColor = 'black',
  dataQaid = 'check-icon',
}) => {
  return (
    <BaseIcon
      icon={Check1}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      dataQaid={dataQaid}
    />
  );
};

export const CheckCircleIcon: React.FC<IconProps> = ({
  iconSize = 24,
  iconColor = 'green',
  dataQaid = 'check-circle-icon',
  className,
}) => {
  return (
    <BaseIcon
      icon={CheckCircle}
      iconSize={iconSize}
      iconStrokeColor="none"
      iconFillColor={iconColor}
      dataQaid={dataQaid}
      className={className}
    />
  );
};

export const MusicNoteIcon: React.FC<IconProps> = ({
  iconSize = 16,
  iconColor,
  onClick,
  disabled = false,
  dataQaid = 'music-note-icon',
}) => {
  return (
    <BaseIcon
      icon={MusicNote2}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const BaggageIcon: React.FC<IconProps> = ({
  iconSize = 16,
  iconColor = 'backToBlack',
  onClick,
  disabled = false,
  dataQaid = 'baggage-icon',
}) => {
  return (
    <BaseIcon
      icon={Baggage}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const BandcampSocialIcon: React.FC<IconProps> = ({
  iconSize = 20,
  iconColor = 'green',
  onClick,
  disabled = false,
  dataQaid = 'bandcamp-social-icon',
}) => {
  return (
    <BaseIcon
      icon={Bandcamp1}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const FacebookSocialIcon: React.FC<IconProps> = ({
  iconSize = 20,
  iconColor = 'green',
  onClick,
  disabled = false,
  dataQaid = 'facebook-social-icon',
}) => {
  return (
    <BaseIcon
      icon={SocialMediaFacebook}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const InstagramSocialIcon: React.FC<IconProps> = ({
  iconSize = 20,
  iconColor = 'green',
  onClick,
  disabled = false,
  dataQaid = 'instagram-social-icon',
}) => {
  return (
    <BaseIcon
      icon={SocialInstagram1}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const SoundcloudSocialIcon: React.FC<IconProps> = ({
  iconSize = 20,
  iconColor = 'green',
  onClick,
  disabled = false,
  dataQaid = 'soundcloud-social-icon',
}) => {
  return (
    <BaseIcon
      icon={SocialMusicSoundcloud}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const SpotifySocialIcon: React.FC<IconProps> = ({
  iconSize = 20,
  iconColor = 'green',
  onClick,
  disabled = false,
  dataQaid = 'spotify-social-icon',
}) => {
  return (
    <BaseIcon
      icon={SocialMusicSpotify2}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const TwitterSocialIcon: React.FC<IconProps> = ({
  iconSize = 20,
  iconColor = 'green',
  onClick,
  disabled = false,
  dataQaid = 'twitter-social-icon',
}) => {
  return (
    <BaseIcon
      icon={SocialMediaTwitter}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const WebsiteSocialIcon: React.FC<IconProps> = ({
  iconSize = 20,
  iconColor = 'green',
  onClick,
  disabled = false,
  dataQaid = 'website-social-icon',
}) => {
  return (
    <BaseIcon
      icon={BrowserPageAccount}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const YoutubeSocialIcon: React.FC<IconProps> = ({
  iconSize = 20,
  iconColor = 'green',
  onClick,
  disabled = false,
  dataQaid = 'youtube-social-icon',
}) => {
  return (
    <BaseIcon
      icon={SocialVideoYoutubeClip}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const socialIcons = {
  urlBandcamp: BandcampSocialIcon,
  urlFacebook: FacebookSocialIcon,
  urlInstagram: InstagramSocialIcon,
  urlSoundcloud: SoundcloudSocialIcon,
  urlSpotify: SpotifySocialIcon,
  urlTwitter: TwitterSocialIcon,
  urlVKontacte: null,
  urlWebsite: WebsiteSocialIcon,
  urlYoutube: YoutubeSocialIcon,
};
